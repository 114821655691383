import {useContext} from 'react'
import {Navigate, Outlet, useLocation} from "react-router-dom";
import AuthContext from './context/AuthProvider'

export default function PrivateRoutes() {

    const {auth} = useContext(AuthContext);
    const location = useLocation();
    const {loading} = useContext(AuthContext);

    if(loading){
        return auth.user ? <Outlet/> : <Navigate to = { '/admin_section/login'}  state={{ from: location }} replace/>}
    
}

