import { createContext, useState, useEffect } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});
    const [loading, setLoading ] = useState(false);

    
    useEffect(() => {
        const data = window.sessionStorage.getItem('USER_DATA');
        if ( data !== null ) setAuth(JSON.parse(data));
        setLoading(true);
      }, []);

    return (
        <AuthContext.Provider value={{auth, setAuth, loading}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;