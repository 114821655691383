import React, {Suspense} from "react";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { AuthProvider } from "./context/AuthProvider";
import PrivateRoutes from "./PrivateRoutes";

import "./App.css"

const Home  = React.lazy(() => import('./pages/Home'));
const Gallery  = React.lazy(() => import('./pages/Gallery'));
const DoveSiamo = React.lazy(() => import('./pages/DoveSiamo'));
const Contatti = React.lazy(() => import('./pages/Contatti'));

// Administrative pages
const Login = React.lazy(() => import('./pages/admin/login_page/Login'));
const RefreshPassword = React.lazy(() => import('./pages/admin/login_page/RefreshPassword'));
const AdminHome = React.lazy(() => import('./pages/admin/home_page/AdminHome'));
const AdminNews = React.lazy(() => import('./pages/admin/adminNews/AdminNews'));
const AdminImageSlider = React.lazy(() => import('./pages/admin/adminImageSlider/AdminImageSlider'));
const AdminGallery = React.lazy(() => import('./pages/admin/adminGallery/AdminGallery'));

function App(){
    return(
        <Router>
            <Suspense fallback={<div className="loading">Loading...</div>}>
            <AuthProvider>
                <Routes>
                    <Route exact path="/" element = {<Home />}/>
                    <Route exact path="/Gallery"  element = {<Gallery />}/>
                    <Route exact path="/DoveSiamo"  element = {<DoveSiamo />}/>
                    <Route exact path="/Contatti"  element = {<Contatti/>}/>
                
                    {/** Administrative pages */}
                    <Route element={<PrivateRoutes/>}>
                        <Route exact path="admin_section"  element = {<AdminHome/>}/>
                        <Route exact path="admin_section/ImageSlider"  element = {<AdminImageSlider/>}/>
                        <Route exact path="admin_section/Gallery"  element = {<AdminGallery/>}/>
                        <Route exact path="admin_section/News"  element = {<AdminNews/>}/>
                    </Route>
                    <Route path="/admin_section/login" element = {<Login/>} />
                    <Route path="/refreshPassword" element = {<RefreshPassword/>}/>
                </Routes>
            </AuthProvider>
            </Suspense>
        </Router>
    );
}

export default App